import { render, staticRenderFns } from "./booking-tour.vue?vue&type=template&id=0793d353&scoped=true"
import script from "./booking-tour.vue?vue&type=script&lang=js"
export * from "./booking-tour.vue?vue&type=script&lang=js"
import style0 from "./booking-tour.vue?vue&type=style&index=0&id=0793d353&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0793d353",
  null
  
)

export default component.exports