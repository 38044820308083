<template>
    <div class="page" v-loading.fullscreen.lock="fullscreenLoading">
        <div v-if="!fullscreenLoading">
            <div class="summary-card" >
                <div class="summary-step" id="pdf-details">
                    <div>
                        <div class="pdf-details" style="display: flex;align-items: center;height: 8rem;">
                            <div style="flex:2;">
                                <img style="width: 5rem;height: 5rem;" src="../../assets/logo.png"/>
                            </div>
                            <div style="flex:1;text-align: right;">
                                <h4>
                                   Fun Trip Holiday<br>
                                   <label v-if="booking.booking.account.area=='澳洲Australia'">ABN 18683576721</label>
                                </h4>
                                <div style="font-size: 1rem;margin-bottom: 0.5rem;font-weight: 400;">
                                    www.funtripholidayus.net
                                </div>
                                <div style="font-size: 1rem;font-weight: 400;">
                                    booking@funtripholidayus.com
                                </div>
                            </div>
                        </div>
                        <div class="pdf-details" style="border: 1px solid #000000;padding: 2rem;margin-bottom: 2.5rem;">
                            <el-row :gutter="48" style="margin-bottom: 1rem;">
                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.invoiceDate') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;">
                                        <label>{{ new Date().toLocaleString() }}</label>
                                    </div>
                                    
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.agentName') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;">
                                        <label>{{ booking.booking.account.accountName }}</label>
                                    </div>
                                </el-col>
                            </el-row>

                            

                            <el-row :gutter="48"  style="margin-bottom: 1rem;">
                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.consultant') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;">
                                        <label>{{ booking.booking.consultant }}</label>
                                    </div>
                                    
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.invoiceNo') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;">
                                        <label>{{ booking.booking.bookingNo }}</label>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row :gutter="48"  style="margin-bottom: 1rem;">
                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.refNo') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;width: 100%;">
                                        <label>{{ booking.booking.refNo?booking.booking.refNo:"N/A" }}</label>
                                    </div>
                                    
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-weight: bold;font-size: 1.2rem;">
                                        <label>{{ $t('bookingInvoice.bookingID') }}</label>
                                    </div>
                                </el-col>

                                <el-col :span="6">
                                    <div style="height: 100%;line-height: 2rem;font-size: 1.2rem;">
                                        <label>{{ booking.booking.id }}</label>
                                    </div>
                                </el-col>
                                
                               
                            </el-row>
                            
                        </div>
                        <div class="pdf-details">
                            <el-table
                                :data="tableData"
                                border
                                :cell-style="cellStyle"
                                :header-cell-style="headerCellStyle"
                                
                                style="width: 100%">
                                <el-table-column
                                    prop="name"
                                    :label="$t('bookingInvoice.name')"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="description"
                                    :label="$t('bookingInvoice.description')"
                                    width="350"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="unitPrice"
                                    :label="$t('bookingInvoice.unitPrice')"
                                    >
                                </el-table-column>
                                <el-table-column
                                    prop="qty"
                                    :label="$t('bookingInvoice.qty')">
                                </el-table-column>
                                <el-table-column
                                    prop="total"
                                    :label="$t('bookingInvoice.subtotal')">
                                </el-table-column>
                            </el-table>
                        </div>

                        <div class="pdf-details" style="display: flex;">
                            <div v-if="booking.booking.bookingStatus==2" style="text-align: left;flex: 1;color:red">
                                <h3>{{ $t('bookingInvoice.cancelled') }}</h3>
                            </div>
                            <div style="text-align: right;;flex: 1;">
                                <h3>{{$t('bookingInvoice.total')}}: {{ monetaryUnit+" "+totalFee }}</h3>
                            </div>
                            
                        </div>
                    </div>

                    <div class="pdf-details" style="margin-bottom: 1rem;">
                        <h2 class="pdf-details">{{ $t('bookingInvoice.termAndCondition') }}</h2>
                        <div class="pdf-details" style="color: black;font-size: 1.32rem;">
                            <div class="pdf-details term-item">1.此优惠价格仅限能以流利中文（国语/粤语）进行交流的美加澳籍华人华侨参团，否则价格另议。</div>
                            <div class="pdf-details term-item">The tour fee may change if passenger does NOT speak fluent Mandarin or Cantonese. </div>
                            <div class="pdf-details term-item">2. 仅提供中文导游服务，港澳台，英文配偶及非美加澳籍华人或有附加费。</div> 
                            <div class="pdf-details term-item">Tour guide speaks Chinese ONLY. Surcharge may apply if passenger is NOT Overseas¬ Chinese. </div>
                            <div class="pdf-details term-item">3. 旅客报名后如因疾病、意外受伤、个人原因等原因更改团期或退团可能产生损失，请参考【更改及取消条款】。为避免旅客因各种原因无法参团所带来的损失，强烈建议客人购买旅游保险（如医疗、疾病、意外或中途取消 等）。</div>
                            <div class="pdf-details term-item">Change or cancellation fee may occurred after booking ，please refer to 【Change or Cancellation policy】as below. In order to avoiding any economic loss of passengers cannot join the tour, we advise passenger to purchase Travel Insurance (Emergency Hospital/Medical and Trip Cancellation/Interruption). </div>
                            <div class="pdf-details term-item">4. 团费价格以两人一房基准计算，如遇其中一方取消，没有或中断参团，另一方须补付单人房差价。</div> 
                            <div class="pdf-details term-item">Passenger should pay the single room supplement if the companion cancels or no show or interrupts the tour. </div> 
                            <div class="pdf-details term-item">5. 旅客未在指定地点指定时间参加旅行团均被视为自动放弃整个行程。团队出发后，任何提前购买的门票费用，项目费用，餐费等恕不退还。</div> 
                            <div class="pdf-details term-item">Passenger who does NOT attend the tour at the designated time and place is deemed to be giving up the entire tour. After the tour starts, any advanced purchased of the ticket costs, program fees, meals, etc. will NOT be refunded. </div> 
                            <div class="pdf-details term-item">6. 旅客须自行承担随身财物的保管责任，如在参团期间遭遇失窃而引致金钱及物品的损失，本公司、随团司机及导游均不负有任何责任。</div>  
                            <div class="pdf-details term-item">Passenger is responsible to keep and protect his personal property and personal belongings. Tianbao Travel, drivers and tour guides take no responsibility on it. </div>
                            <div class="pdf-details term-item">7. 如遇不可预见或无法避免的情况或事件发生（如航班晚点/取消，自然灾害，恶劣天气环境等等），本公司保留更改或取消行程之权利，团友不得异议。</div>
                            <div class="pdf-details term-item">In case of any unforeseen or unavoidable circumstances or events occured (such as flights delays / cancellations, natural disasters, adverse weather conditions, etc.), Tianbao Travel reserves the right to change or cancel the itinerary. </div> 
                            <div class="pdf-details term-item">8. 我们在此提醒您可能需要办理目的地国家之签证，如无有效签证，您或会被拒绝登机，请自行负责。</div>
                            <div class="pdf-details term-item"> Please ensure you have valid passport and VISA to the Destination, passenger is responsible for all travel documents validity. </div> 
                            <div class="pdf-details term-item">9. 请于报名时自行检查所有报名信息均符合参团要求，并于参团时携带有效护照及证件原件。如因所提供的信息，包括抵离日期、航班时间、地点、参团人员身份及旅行证件等错误或不符合参 团要求，所引起的相关责任及经济损失，请恕本公司无法负责。</div>  
                            <div class="pdf-details term-item">Please ensure all the registration information is correct and meets the tour requirement. Besides passenger should bring the valid passport and original documents when joining the tour. Tianbao Travel couldn't suffer any loss if the information provided do NOT correct, such as date, flight, city, travel document or identify of participants. </div> 
                            
                            <div class="pdf-details term-item">更改及取消条款</div> 
                            <div class="pdf-details term-item">Change or Cancellation Policy</div> 
                            <div class="pdf-details term-item">取消訂位需書面方式通知本公司辦理，恕不接受口頭取消。無論任何非本公司原因而導致的取消訂位均有可能會產生罰款(亞洲線路以及包含遊船、機票等線路抵達日期前30日內取消團費+自費專案+綜合服務費全損。其他各線路損失費各有不同，請諮詢客服，我公司保留最終解釋權）。旅客未在指定地點指定時間參加旅行團均被視為自动放棄整個行程。團隊出發後，任何提前购买的門票費用，項目費用，餐費等恕不退還。此外不論在出發日期前多久，如果代理旅行社國際機票已開，則因此產生的全部退票費用，包括各種手續費等也需按航空公司規定请自行乘擔.</div> 
                            <div class="pdf-details term-item">Cancellation of reservations requires written notification to our company, and verbal cancellations are not accepted. Any cancellation of reservations caused by reasons other than our company may result in fines (cancellation of group fees, self-funded projects, and comprehensive service fees within 30 days before the arrival date of Asian routes and routes including cruise ships, tickets, etc. will result in total loss. The loss fees for other routes may vary, please consult customer service, and our company reserves the final right to explain). Passengers who fail to join the tour group at the designated location and time will be deemed to have automatically given up the entire itinerary. After the team departs, any pre purchased ticket fees, project fees, meal expenses, etc. will not be refunded. In addition, regardless of how long before the departure date, if the international ticket has been issued by the travel agency, all refund fees, including various handling fees all by self responsibility.</div> 

                            <div class="pdf-details term-item">趣旅游对以上内容保留最终解释权利。 </div> 
                            <div class="pdf-details term-item">Fun Trip Holiday reserved the right of final explanation as above. </div> 
                            <div class="pdf-details term-item">您已经阅读，理解并且同意接受以上团队旅游团款。 </div> 
                            <div class="pdf-details term-item">You have read, understood and accepted the above terms and conditions.</div> 
                        </div>
                    </div>
                    
                </div>
                
                
                    
            </div>
        </div>
        <div style="text-align: center;margin-top: 1rem;">
            <el-button type="primary" @click="downloadPDFClick">{{ $t('bookingInvoice.downloadPDF') }}</el-button>
        </div>
    </div>
  </template>
  
  <script>
  
import htmlPdf from '@/service/pdf';

  export default {
    props:{BookingId:null},
    name: 'booking-summary',
    data(){
        return {
            booking:{
                booking:{
                    account:{
                        area:""
                    }
                }
            },
            fullscreenLoading:true,
            tableData:[],
            totalFee:0,
            monetaryUnit:"",
            cellStyle:{color:"black",fontSize:"1.1rem"},
            headerCellStyle:{color:"black",fontSize:"1.2rem"}
        }
    },
    mounted(){
        this.getBookingDetails(this.BookingId);
    },
    methods:{
        getBookingDetails(id){
            this.fullscreenLoading=true;
            let that = this;
            this.$httpservice.get('api/Booking/GetInvoice',{id:id}).then(result => {
                this.fullscreenLoading=false;
                if(result.status==200){
                    if(result.body.code=='200'){
                        that.booking = result.body.data;
                        switch(that.booking.booking.account.area){
                          case "美国USA":
                            that.monetaryUnit = "USD"
                            break;
                          case "加拿大CANADA":
                            that.monetaryUnit = "CAD"
                            break;
                          case "澳洲Australia":
                            that.monetaryUnit = "AUD"
                            break;
                        }
                        //make table data
                        let tourInfo={
                            name:this.$i18n.locale=='en'?"Tour Name":"行程名稱",
                            description:that.booking.booking.trip.tourName,
                            unitPrice:"",
                            qty:"",
                            total:""
                        }
                        that.tableData.push(tourInfo);

                         //make table data
                         let tourCode={
                            name:this.$i18n.locale=='en'?"Tour Code":"行程編號",
                            description:that.booking.booking.trip.tourCode,
                            unitPrice:"",
                            qty:"",
                            total:""
                        }
                        that.tableData.push(tourCode);

                        let tourDate={
                            name:this.$i18n.locale=='en'?"Tour Date":"行程日期",
                            description:that.booking.booking.tourDate.split(" ")[0],
                            unitPrice:"",
                            qty:"",
                            total:""
                        }
                        that.tableData.push(tourDate);

                        let passengerDescriptions="";
                        that.booking.booking.passengers.forEach(passenger => {
                            passengerDescriptions+=passenger.firstName+" "+passenger.lastName+"/";
                        });
                        if(passengerDescriptions.length>0){
                            passengerDescriptions = passengerDescriptions.substring(0, passengerDescriptions.length - 1);
                        }
                        let passengerInfos={
                            name:this.$i18n.locale=='en'?"Passengers":"旅客",
                            description:passengerDescriptions,
                            unitPrice:"",
                            qty:that.booking.booking.passengers.length,
                            total:that.booking.booking.passengers.length
                        }
                        that.tableData.push(passengerInfos);

                        let adult={
                            name:"ADT",
                            description:this.$i18n.locale=='en'?"Adult":"成人",
                            unitPrice:that.booking.adultPrice,
                            qty:that.booking.adultNum,
                            total:that.booking.adultPrice*that.booking.adultNum
                        }
                        that.tableData.push(adult);
                        that.totalFee+=adult.total;

                        let adultCommission={
                            name:"",
                            description:this.$i18n.locale=='en'?"Commission":"佣金",
                            unitPrice:(0-that.booking.adultCommission),
                            qty:that.booking.adultNum,
                            total:(0-that.booking.adultCommission)*that.booking.adultNum
                        }
                        that.tableData.push(adultCommission);
                        that.totalFee-=(0-adultCommission.total);

                        let childWithBed={
                            name:"CHD",
                            description:this.$i18n.locale=='en'?"Child With bed":"孩童占床",
                            unitPrice:that.booking.childWithBedPrice,
                            qty:that.booking.childWithBedNum,
                            total:that.booking.childWithBedPrice*that.booking.childWithBedNum
                        }
                        that.tableData.push(childWithBed);
                        that.totalFee+=childWithBed.total;


                        let childWithBedCommission={
                            name:"",
                            description:this.$i18n.locale=='en'?"Commission":"佣金",
                            unitPrice:(0-that.booking.childWithBedCommission),
                            qty:that.booking.childWithBedNum,
                            total:(0-that.booking.childWithBedCommission)*that.booking.childWithBedNum
                        }
                        that.tableData.push(childWithBedCommission);
                        that.totalFee-=(0-childWithBedCommission.total);

                        let childWithoutBed={
                            name:"",
                            description:this.$i18n.locale=='en'?"Child Without bed":"孩童不占床",
                            unitPrice:that.booking.childWithoutBedPrice,
                            qty:that.booking.childWithoutBedNum,
                            total:that.booking.childWithoutBedPrice*that.booking.childWithoutBedNum
                        }
                        that.tableData.push(childWithoutBed);
                        that.totalFee+=childWithoutBed.total;

                        let childWithoutBedCommission={
                            name:"",
                            description:this.$i18n.locale=='en'?"Commission":"佣金",
                            unitPrice:(0-that.booking.childWithoutBedCommission),
                            qty:that.booking.childWithoutBedNum,
                            total:(0-that.booking.childWithoutBedCommission)*that.booking.childWithoutBedNum
                        }
                        that.tableData.push(childWithoutBedCommission);
                        that.totalFee-=(0-childWithoutBedCommission.total);


                        /*
                        let infantWithBed={
                            name:"INF",
                            description:"Infant With bed",
                            unitPrice:that.booking.infantWithBedPrice,
                            qty:that.booking.infantWithBedNum,
                            total:that.booking.infantWithBedPrice*that.booking.infantWithBedNum
                        }
                        that.tableData.push(infantWithBed);
                        that.totalFee+=infantWithBed.total;

                        let infantWithBedCommission={
                            name:"",
                            description:"Commission",
                            unitPrice:that.booking.infantWithBedCommission,
                            qty:that.booking.infantWithBedNum,
                            total:that.booking.infantWithBedCommission*that.booking.infantWithBedNum
                        }
                        that.tableData.push(infantWithBedCommission);
                        that.totalFee-=infantWithBedCommission.total;


                        let infantWithoutBed={
                            name:"",
                            description:"Infant Without bed",
                            unitPrice:that.booking.infantWithoutBedPrice,
                            qty:that.booking.infantWithoutBedNum,
                            total:that.booking.infantWithoutBedPrice*that.booking.infantWithoutBedNum
                        }
                        that.tableData.push(infantWithoutBed);
                        that.totalFee+=infantWithoutBed.total;

                        let infantWithoutBedCommission={
                            name:"",
                            description:"Commission",
                            unitPrice:that.booking.infantWithoutBedCommission,
                            qty:that.booking.infantWithoutBedNum,
                            total:that.booking.infantWithoutBedCommission*that.booking.infantWithoutBedNum
                        }
                        that.tableData.push(infantWithoutBedCommission);
                        that.totalFee-=infantWithoutBedCommission.total;
                        */

                        
                        let comprehensiveServiceFee={
                            name:"OTHER",
                            description:this.$i18n.locale=='en'?("Comprehensive Service Fee, Is prepaid:"+(that.booking.booking.tippingPrepaid==true?"Yes":"No")):("綜合服務費, 是否預付:"+(that.booking.booking.tippingPrepaid==true?"是":"否")),
                            unitPrice:that.booking.booking.comprehensiveServiceFee,
                            qty:that.booking.comprehensiveServiceNum,
                            total:that.booking.booking.comprehensiveServiceFee*that.booking.comprehensiveServiceNum
                        }
                        that.tableData.push(comprehensiveServiceFee);
                        if(that.booking.booking.tippingPrepaid==true){
                            that.totalFee+=comprehensiveServiceFee.total;
                        }else{
                            comprehensiveServiceFee.total=0;
                        }

                        let compulsoryProgramFee={
                            name:"",
                            description:this.$i18n.locale=='en'?("Compulsory Program Fee, Is prepaid:"+(that.booking.booking.compulsoryPrepaid==true?"Yes":"No")):("指定項目費用, 是否預付:"+(that.booking.booking.compulsoryPrepaid==true?"是":"否")),
                            unitPrice:that.booking.booking.compulsoryProgramFee,
                            qty:that.booking.compulsoryProgramFeeNum,
                            total:that.booking.booking.compulsoryProgramFee*that.booking.compulsoryProgramFeeNum
                        }
                        that.tableData.push(compulsoryProgramFee);

                        if(that.booking.booking.compulsoryPrepaid==true){
                            that.totalFee+=compulsoryProgramFee.total;
                        }else{
                            compulsoryProgramFee.total=0
                        }
                       

                        let simpleSupFee={
                            name:"",
                            description:this.$i18n.locale=='en'?"Single Supp":"單間差",
                            unitPrice:that.booking.booking.simpleSup,
                            qty:that.booking.booking.singleRoom,
                            total:that.booking.booking.singleRoom*that.booking.booking.simpleSup
                        }
                        that.tableData.push(simpleSupFee);
                        that.totalFee+=simpleSupFee.total;

                        let extraItem={
                            name:"Extra",
                            description:this.$i18n.locale=='en'?"Extra Item":"額外項",
                            unitPrice:that.booking.booking.extraItem,
                            qty:1,
                            total:that.booking.booking.extraItem
                        }
                        that.tableData.push(extraItem);
                        that.totalFee+=extraItem.total;

                        let extraItemDescription={
                            name:"",
                            description:this.$i18n.locale=='en'?("Extra Item Description: "+that.booking.booking.extraItemDescription):("額外項描述: "+that.booking.booking.extraItemDescription),
                            unitPrice:"",
                            qty:"",
                            total:""
                        }
                        that.tableData.push(extraItemDescription);

                        let extralCommission={
                            name:"",
                            description:this.$i18n.locale=='en'?"Commission Adjustment":"佣金調整",
                            unitPrice:(0-that.booking.booking.extraCommission),
                            qty:1,
                            total:(0-that.booking.booking.extraCommission)
                        }
                        that.tableData.push(extralCommission);
                        that.totalFee-=(0-extralCommission.total);

                        let extralCommissionDescription={
                            name:"",
                            description:this.$i18n.locale=='en'?"Commission Adjustment Description":"佣金調整描述",
                            unitPrice:that.booking.booking.extraCommissionDescription,
                            qty:"",
                            total:""
                        }
                        that.tableData.push(extralCommissionDescription);

                        

                    }else{
                        that.$message({
                                message: 'Get Booking info Failed',
                                type: 'warning'
                        });
                    }
                }
            });
        },
        downloadPDFClick(){
            const lableList = document.getElementsByClassName('pdf-details');
            htmlPdf("Invoice", document.querySelector('#pdf-details'),lableList,null,true);
        }
    }
   
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" scoped>
    .term-item{
        margin-top: 0.5rem;
    }
    .summary-card{
        margin-top: 2rem;
        width: 100%;
        background-color: #F5F7FB;
        display: flex;
        justify-content: center;
        .summary-step{
            width: 60%;
            .row{
                margin-top: 1.5rem;
            }
            .key{
                color:#444444;
                font-size: 1.12rem;
            }
            .value{
                color:#999999;
                font-size: 1.12rem;
            }
        }
        .booking-summary{
            background-color: #408F21;height: 3.125rem;color: white;display: flex;justify-content: center;align-items: center;
            
        }
    }
  </style>
  